import React from "react";
import { useSelector } from "react-redux";

const SelectPostion = ({ nameValue, value, handleChange }) => {
  const { data } = useSelector((state) => state.data);

  return (
    <select
      className="form-control main_inner_dropdown"
      name={nameValue}
      value={value ?? value}
      onChange={handleChange}
    >
      {data?.positions?.map((i) => (
        <option key={i.id} value={i.position} selected={i.position}>
          {i.position}
        </option>
      ))}
    </select>
  );
};

export default SelectPostion;

// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { getPosition } from '../api/postion';

// const SelectPostion = ({nameValue,value,handleChange}) => {
//   const dispatch = useDispatch();
//   const [allPosition, setAllPosition] = useState([]);

//     useEffect(() => {
//       getPositionData();
//     }, []);

//   const getPositionData = async () => {
//     try {
//       const response = await dispatch(getPosition());
//       setAllPosition(response?.data?.positions);
//     } catch (error) {
//       console.error('Failed to fetch positions:', error);
//     }
//   };

//   return (
//     <select
//     className="form-control main_inner_dropdown"
//     name={nameValue}
//     value={value??value}
//     onChange={handleChange}
//   >
//     {allPosition?.map((i) => (
//       <option key={i.id} value={i.position} selected={i.position}>
//         {i.position}
//       </option>
//     ))}
//   </select>
//   );
// };

// export default SelectPostion;
