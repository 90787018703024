import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectDefaultPosition from "../SelectDefaultPosition";
import DeleteTableEmployee from "../delete/DeleteTableEmployee";
import Pagination from "../Pagination";
import { getExEmployeeTime } from "../../helper/getTimePeriod";
import { setExEmployeeCount } from "../../store/DashboardSlice";
import Stars from "../extras/Stars";
import TableEmptyMsg from "./TableEmptyMsg";
import { getExEmployee } from "../../api/employee";
const CurrentEmployeeTable = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [position, setPosition] = useState("");

  const dispatch = useDispatch();

  const fetchEmployees = async (page) => {
    setLoading(true);
    dispatch(getExEmployee(page, searchText, position))
      .then((res) => {
        const data = res.data;
        setEmployees(data?.exEmployee?.data);
        setTotalPages(data?.exEmployee?.last_page);
        dispatch(setExEmployeeCount(data?.exEmployee?.total));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchEmployees(currentPage);
  }, [currentPage, searchText, position]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <div className="container-fluid viewemployee main_inner_padding">
      <div className="row">
        <div className="col-lg-5">
          <h3> Ex Employees</h3>
        </div>
        <div className="col-lg-4 col-md-6 pb-4">
          <SelectDefaultPosition changePostion={setPosition} />
        </div>
        <div className="col-lg-3  col-md-6 pb-4">
          <div className="search_button">
            <input
              type="search"
              className="form-control inner_search_icon"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <i className="fa fa-search navi-search"></i>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div id="table-scroll" className="table-scroll">
            <table id="main-table" className="main-table table">
              <thead>
                <tr>
                  <th
                    className="sticky-column-1 column-1"
                    scope="col"
                    style={{ background: "#e1e9ed" }}
                  >
                    Employee ID
                  </th>
                  <th
                    className="sticky-column-2"
                    scope="col"
                    style={{ background: "#e1e9ed" }}
                  >
                    Name{" "}
                  </th>
                  <th
                    className="sticky-column-3"
                    scope="col"
                    style={{ background: "#e1e9ed" }}
                  >
                    Email
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Phone Number
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Designation
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Time Period
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Rating
                  </th>
                  <th
                    className="sticky-column-last"
                    scope="col"
                    style={{ background: "#e1e9ed" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {loading ? (
                <tr>
                  <td colSpan="9" style={{ height: "200px" }}>
                    <TableEmptyMsg
                      loading={loading}
                      dataLength={employees?.length}
                    />
                  </td>
                </tr>
              ) : (
                <tbody>
                  {!employees?.length ? (
                    <tr>
                      <td colSpan="8">
                        <Link
                          to="/add-exemployee-review"
                          className="addempbtn1"
                        >
                          {" "}
                          <button className="btn addempbtn">
                            Add Ex Employee{" "}
                          </button>
                        </Link>
                        <TableEmptyMsg
                          loading={loading}
                          dataLength={employees?.length}
                        />
                      </td>
                    </tr>
                  ) : (
                    employees?.map((i, index) => (
                      <tr key={index} className="table_data_background">
                        {/* <td className="sticky-column-1">{index + 1}</td> */}
                        <td className="sticky-column-1 column-1">{i.emp_id}</td>
                        <td className="sticky-column-2">{i.emp_name}</td>
                        <td className="sticky-column-3">{i.email}</td>
                        <td>{i.phone}</td>
                        <td>{i.position}</td>
                        <td>
                          {getExEmployeeTime(
                            i?.date_of_joining,
                            i?.date_of_leaving
                          )}
                        </td>
                        <td>
                          <Stars rating={i?.overall_rating} />
                        </td>
                        <td className="sticky-column-last">
                          <Link
                            to={`/view-exemployee/${i.sid}`}
                            style={{ textDecoration: "none" }}
                          >
                            <button type="button" className="btn  act_btn_v">
                              <i className="fas fa-eye eye-cs"></i>
                            </button>
                          </Link>
                          &nbsp; &nbsp;
                          <DeleteTableEmployee id={i.sid} />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4"></div>
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};
export default CurrentEmployeeTable;
