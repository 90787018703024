import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import DashboardSlice from "./DashboardSlice";
import FormSlice from "./FormSlice";
import employeeSlice from "./employeeSlice";
import toggleSlice from "./toggleSlice";
import dataReducer, { fetchPosition } from './positionSlice'

const isLoggedIn = !!localStorage.getItem("token");


const store =  configureStore({
  reducer: {
   dashboardData:DashboardSlice,
   form: FormSlice,
   employeeData:employeeSlice,
   toggle:toggleSlice,
   data:dataReducer,
   middleware:[thunk]
  },
});
if(isLoggedIn){
  store.dispatch(fetchPosition());
}

export default store














// import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
// import DashboardSlice from "./DashboardSlice";
// import FormSlice from "./FormSlice";
// import employeeSlice from "./employeeSlice";
// import toggleSlice from "./toggleSlice";
// import dataReducer, { fetchData } from './positionSlice'
// export default configureStore({
//   reducer: {
//    dashboardData:DashboardSlice,
//    form: FormSlice,
//    employeeData:employeeSlice,
//    toggle:toggleSlice,
//    data:dataReducer,
//    middleware:[thunk]
//   },
// });