import React from 'react'
import LayoutOrpect from '../Index'
import Faq from './Faq'
import Faqstext from './Faqstext'

const Faqs = () => {
  return (
   <LayoutOrpect>
    <Faqstext />
    < Faq/>

   </LayoutOrpect>

  )
}

export default Faqs