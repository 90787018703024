import React from 'react'
import LayoutOrpect from '../Index'
import HeroBanner from './HeroBanner'
import WhoWeAre from './WhoWeAre'
import Aboutsection from './Aboutsection'

const AboutUs  = () => {

 
  return (

  <LayoutOrpect>
<HeroBanner />
<WhoWeAre />
<Aboutsection />
  </LayoutOrpect>
  
  )
}

export default AboutUs