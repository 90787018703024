import Navigation from './navigation/index';
import { BrowserRouter as Router} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { logoutAllTabs } from './api/logout';

function App() {


  useEffect(() => {
    logoutAllTabs()
   }, [])
   
  return (
    <>
    <ToastContainer/>
    <Router>
      <Navigation/>
    </Router>
    </>
    
  );
}

export default App;
