import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectDefaultPosition from "../SelectDefaultPosition";
import DeleteTableEmployee from "../delete/DeleteTableEmployee";
import { setCurrentEmployeeCount } from "../../store/DashboardSlice";
import Pagination from "../Pagination";
import { getTimePeriod } from "../../helper/getTimePeriod";
import TableEmptyMsg from "./TableEmptyMsg";
import { getCurrentEmployee } from "../../api/employee";

const CurrentEmployeeTable = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [position, setPosition] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    fetchEmployees(currentPage);
  }, [currentPage, searchText, position]);

  const fetchEmployees = async (page) => {
    setLoading(true);
    dispatch(getCurrentEmployee(page,searchText,position))
      .then((res) => {
        const data = res.data;
        setEmployees(data?.currentEmployees?.data);
        setTotalPages(data?.currentEmployees?.last_page);
        dispatch(setCurrentEmployeeCount(data?.currentEmployees?.total));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // setLoading(true);
    // try {
    //   const headers = {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   };

    //   const response = await axios.get(
    //     `${BASE_URL}/getCurrentEmployees?page=${page}&searchText=${searchText}&position=${position}`,
    //     {
    //       headers: headers,
    //     }
    //   );
      // const data = response.data;
      // setEmployees(data?.currentEmployees?.data);
      // setTotalPages(data?.currentEmployees?.last_page);
      // dispatch(setCurrentEmployeeCount(data?.currentEmployees?.total));
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <div className="container-fluid viewemployee main_inner_padding">
      <div className="row">
        <div className="col-lg-5">
          <h3> Current Employees</h3>
        </div>
        <div className="col-lg-4 col-md-6 pb-4">
          <SelectDefaultPosition changePostion={setPosition} />
        </div>
        <div className="col-lg-3  col-md-6 pb-4">
          <div className="search_button">
            <input
              type="search"
              className="form-control inner_search_icon"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <i className="fa fa-search navi-search"></i>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div id="table-scroll" className="table-scroll">
            <table id="main-table" className="main-table table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky-column-1 column-1"
                    style={{ background: "#e1e9ed" }}
                  >
                    Employee ID
                  </th>
                  <th
                    scope="col"
                    className="sticky-column-2"
                    style={{ background: "#e1e9ed" }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky-column-3"
                    style={{ background: "#e1e9ed" }}
                  >
                    Email
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Phone Number
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Designation
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Time Period
                  </th>
                  <th
                    scope="col"
                    className="sticky-column-last"
                    style={{ background: "#e1e9ed" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" style={{ height: "200px" }}>
                      <TableEmptyMsg
                        loading={loading}
                        dataLength={employees?.length}
                      />
                    </td>
                  </tr>
                ) : !employees?.length ? (
                  <tr>
                    <td colSpan="7">
                      <Link to="/add-employee" className="addempbtn1">
                        <button className="btn addempbtn">Add Employee </button>
                      </Link>
                      <TableEmptyMsg
                        loading={loading}
                        dataLength={employees?.length}
                      />
                    </td>
                  </tr>
                ) : (
                  employees?.map((i, index) => (
                    <tr key={index} className="table_data_background">
                      <td className="sticky-column-1 column-1">{i.emp_id}</td>
                      <td className="sticky-column-2 ">{i.emp_name}</td>
                      <td className="sticky-column-3">{i.email}</td>
                      <td>{i.phone}</td>
                      <td>{i.position}</td>
                      <td>{getTimePeriod(i?.date_of_joining)}</td>
                      <td className="sticky-column-last">
                        <Link
                          to={`/view-employee/${i.sid}`}
                          style={{ textDecoration: "none" }}
                        >
                          <button type="button" className="btn act_btn_v">
                            <span className="hoverable">
                              <i className="fas fa-eye eye-cs hoverable__main"></i>
                              <span
                                className="hoverable__tooltip"
                                style={{
                                  textAlign: "center",
                                  width: "90px",
                                  position: "absolute",
                                  right: "-2.5rem",
                                  background: "#f6a21e",
                                  top: "-2rem",
                                  padding: "1px",
                                }}
                              >
                                Add Reviews
                              </span>{" "}
                            </span>
                          </button>{" "}
                        </Link>
                        &nbsp;&nbsp;
                        <DeleteTableEmployee id={i?.sid} />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4"></div>
      <div>
        {totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
export default CurrentEmployeeTable;
