
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteEmployeeById } from '../../api/employee';
import Button from '../Button';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';

const DeleteTableEmployee = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const popupRef = React.createRef();

  const handleClosePopup = () => {
    popupRef.current.close();
  };

  const handleDelete = () => {
    setLoading(true);

    if (id) {
      dispatch(deleteEmployeeById(id))
        .then((res) => {
          setLoading(false);
          toast.success("Successfully deleted")
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          handleClosePopup();
        });
    }
  };

  return (
    <>
      <Popup ref={popupRef} trigger={
        <p
          type="button"
          href="#myModal"
          className="btn act_btn_v popupdelete"
          data-toggle="modal"
          onClick={() => popupRef.current.open()}
        >
          <i className="far fa-trash-alt"></i>
        </p>
      } position="left">
        <div>
          <h4 className="modal-title w-100">Are you sure?</h4>
        </div>
        <div className="modal-body">
          <p>Do you really want to delete this record?</p>
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            text="Delete"
            className="btn btn-danger deletebtn"
            onClick={handleDelete}
            loading={loading}
          />
          <button type="button" onClick={handleClosePopup} className="btn btn-secondary cancelbtn" data-dismiss="modal" style={{ margin: "5px" }}>Cancel</button>
        </div>
      </Popup>
    </>
  );
};

export default DeleteTableEmployee;
