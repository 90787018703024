import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import SelectDefaultPosition from "../SelectDefaultPosition";
import DeleteTableEmployee from "../delete/DeleteTableEmployee";
import { getFromLocalStorage } from "../../helper";
import Pagination from "../Pagination";
import { setNonJoinerCount } from "../../store/DashboardSlice";
import { BASE_URL } from "../../api/baseUrl";
import TableEmptyMsg from "./TableEmptyMsg";

const CurrentEmployeeTable = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [position, setPosition] = useState("");
  var [dataIndex,setdataIndex] = useState();

  
  const dispatch = useDispatch();
  const token = getFromLocalStorage("token"); // Replace with your actual authorization token

  const fetchEmployees = async (page) => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(
        `${BASE_URL}/getNonJoiners?page=${page}&searchText=${searchText}&position=${position}`,
        {
          headers: headers,
        }
      );

      const data = response?.data;
      setEmployees(data?.nonJoiners?.data);
      setTotalPages(data?.nonJoiners?.last_page);
      dispatch(setNonJoinerCount(data?.nonJoiners?.total));
      setdataIndex(data?.nonJoiners.from)
      setLoading(false);
    } catch (error) {
      console.log(error ?? error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEmployees(currentPage);
  }, [currentPage, searchText, position]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };


  return (
    <div className="container-fluid viewemployee main_inner_padding">
      <div className="row">
        <div className="col-lg-5">
          <h3> Non Joiners</h3>
        </div>
        <div className="col-lg-4 col-md-6 pb-4">
          <SelectDefaultPosition changePostion={setPosition} />
        </div>
        <div className="col-lg-3  col-md-6 pb-4">
          <div className="search_button">
            <input
              type="search"
              className="form-control inner_search_icon"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <i className="fa fa-search navi-search"></i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div id="table-scroll" className="table-scroll">
            <table id="main-table" className="main-table table">
              <thead>
                <tr>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Sr. No.
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Email
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Phone Number
                  </th>
                  <th scope="col" style={{ background: "#e1e9ed" }}>
                    Designation
                  </th>
                  <th
                    scope="col"
                   
                    style={{ background: "#e1e9ed" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                  <td colSpan="6" style={{ height: "200px" }}>
                  <TableEmptyMsg
                    loading={loading}
                    dataLength={employees?.length}
                  />
                  </td></tr>
                ) : !employees?.length ? (
                  <tr>
                  <td colSpan="6">
                         <Link to="/add-nonjoiner-review"  className="addempbtn1"> <button className="btn addempbtn"> Add Non Joiner </button></Link>
                         <TableEmptyMsg
                    loading={loading}
                    dataLength={employees?.length}                  
                  />
                  </td></tr>
                ) : (
                  employees?.map((i, index) => (
                    <tr key={index} className="table_data_background">
                      <td>{dataIndex++}</td>
                      <td>{i.emp_name}</td>
                      <td>{i.email}</td>
                      <td>{i.phone}</td>
                      <td>{i.position}</td>
                      <td >
                        {" "}
                        <Link
                          to={`/view-nonjoiner/${i.sid}`}
                          style={{ textDecoration: "none" }}
                        >
                          <button type="button" className="btn  act_btn_v">
                            <i className="fas fa-eye eye-cs"></i>
                          </button>{" "}
                        </Link>
                        &nbsp;&nbsp;
                        <DeleteTableEmployee id={i.sid} />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4"></div>
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};
export default CurrentEmployeeTable;
