import React from 'react'
import LayoutOrpect from '../Index'
import Communityheading from './Communityheading'
import Communitytext from './Communitytext'

const Community = () => {
  return (
 <LayoutOrpect>
    <Communityheading />
    <Communitytext />
 </LayoutOrpect>

  )
}

export default Community