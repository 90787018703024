import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Layout from "../component/layout";
import Button from "../component/Button";
import LoadingSpinner from "../component/LoadingSpinner";
import DeletePosition from "../component/delete/DeletePosition";
import EditPosition from "../component/delete/EditPosition";
import { addPostion } from "../api/postion";

const validationSchema = Yup.object().shape({
  positions: Yup.string().required("Please add at least one Position"),
});

const AddPosition = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

    const { data, isLoading} = useSelector((state) => state.data);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(addPostion(values))
      .then((res) => {
        setLoading(false);
        if (res.message === "Successfully added") {
          toast.success("Successfully added");
          window.location.reload();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setLoading(false);
        console.log(err ?? err);
      });
  };

  return (
    <Layout>
      <div className="container-fluid addposition">
        <div className="row">
          <div className="col-lg-12">
            <h3>Add Positions</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 pd-4"></div>

          <div className="col-lg-8 col-md-12 col-sm-12 pd-4">
            <Formik
              initialValues={{ positions: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="pb-4" style={{ margin: "auto" }}>
                  <h5>
                    Add single or multiple positions, using a comma to separate
                    them
                  </h5>
                  <Field
                    type="text"
                    name="positions"
                    className="form-control"
                    placeholder=" (eg:- Backend Developer, Frontend Developer)"
                  />
                  <ErrorMessage
                    name="positions"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div
                  className="col-lg-12 col-sm-12 pb-4"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    className="btn positionSubmit"
                    text="Submit"
                    loading={loading}
                  />
                </div>
              </Form>
            </Formik>
          </div>
          <div className="col-lg-2"></div>
        </div>

        {/* POSITION TABLE */}
        <div className="table-responsive-sm mt-4 table_width">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <table className="table positiontable">
              <thead style={{ textAlign: "center" }}>
                <tr className="table_h">
                  <th scope="col">Sr. No.</th>
                  <th scope="col">Position</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {!data.positions?.length ? (
                  <tr>
                    <td colSpan={3}>No Data Found</td>
                  </tr>
                ) : (

                  data.positions?.map((position, index) => (
                    <tr className="table_data_background" key={index}>
                      <td>{index + 1}</td>
                      <td>{position?.position}</td>
                      <td>
                        <EditPosition
                          positionId={position?.id}
                          oldPosition={position?.position}
                        />
                        &nbsp;&nbsp;
                        <DeletePosition
                          position={position?.position}
                          id={position?.id}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AddPosition;




// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { toast } from "react-toastify";

// import Layout from "../component/layout";
// import Button from "../component/Button";
// import LoadingSpinner from "../component/LoadingSpinner";
// import DeletePosition from "../component/delete/DeletePosition";
// import EditPosition from "../component/delete/EditPosition";
// import { addPostion, getPosition } from "../api/postion";

// const validationSchema = Yup.object().shape({
//   positions: Yup.string().required("Please add at least one Position"),
// });

// const AddPosition = () => {
//   const [loading, setLoading] = useState(true);
//   const [positions, setPositions] = useState([]);
//   const dispatch = useDispatch();

  

//   useEffect(() => {
//     dispatch(getPosition())
//       .then((res) => {
//         setPositions(res?.data?.positions);
//         setLoading(false);
//       })
//       .catch((err) => {
//         toast.error(err?.data?.message);
//         setLoading(false);
//       });
//   }, [dispatch, loading]);

//   const handleSubmit = (values) => {
//     setLoading(true);
//     dispatch(addPostion(values))
//       .then((res) => {
//         setLoading(false);
//         if (res.message === "Successfully added") {
//           toast.success("Successfully added");
//           window.location.reload();
//         } else {
//           toast.error(res?.message);
//         }
//       })
//       .catch((err) => {
//         toast.error(err?.data?.message);
//         setLoading(false);
//         console.log(err ?? err);
//       });
//   };

//   return (
//     <Layout>
//       <div className="container-fluid addposition">
//         <div className="row">
//           <div className="col-lg-12">
//             <h3>Add Positions</h3>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-2 pd-4"></div>

//           <div className="col-lg-8 col-md-12 col-sm-12 pd-4">
//             <Formik
//               initialValues={{ positions: "" }}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               <Form>
//                 <div className="pb-4" style={{ margin: "auto" }}>
//                   <h5>
//                     Add single or multiple positions, using a comma to separate
//                     them
//                   </h5>
//                   <Field
//                     type="text"
//                     name="positions"
//                     className="form-control"
//                     placeholder=" (eg:- Backend Developer, Frontend Developer)"
//                   />
//                   <ErrorMessage
//                     name="positions"
//                     component="div"
//                     className="text-danger"
//                   />
//                 </div>
//                 <div
//                   className="col-lg-12 col-sm-12 pb-4"
//                   style={{ textAlign: "center" }}
//                 >
//                   <Button
//                     className="btn positionSubmit"
//                     text="Submit"
//                     loading={loading}
//                   />
//                 </div>
//               </Form>
//             </Formik>
//           </div>
//           <div className="col-lg-2"></div>
//         </div>

//         {/* POSITION TABLE */}
//         <div className="table-responsive-sm mt-4 table_width">
//           {loading ? (
//             <LoadingSpinner />
//           ) : (
//             <table className="table positiontable">
//               <thead style={{ textAlign: "center" }}>
//                 <tr className="table_h">
//                   <th scope="col">Sr. No.</th>
//                   <th scope="col">Position</th>
//                   <th scope="col">Action</th>
//                 </tr>
//               </thead>
//               <tbody style={{ textAlign: "center" }}>
//                 {!positions?.length ? (
//                   <tr>
//                     <td colSpan={3}>No Data Found</td>
//                   </tr>
//                 ) : (
//                   positions?.map((position, index) => (
//                     <tr className="table_data_background" key={index}>
//                       <td>{index + 1}</td>
//                       <td>{position?.position}</td>
//                       <td>
//                         <EditPosition
//                           positionId={position?.id}
//                           oldPosition={position?.position}
//                         />
//                         &nbsp;&nbsp;
//                         <DeletePosition
//                           position={position?.position}
//                           id={position?.id}
//                         />
//                       </td>
//                     </tr>
//                   ))
//                 )}
//               </tbody>
//             </table>
//           )}
//         </div>
//       </div>
//     </Layout>
//   );
// };

// export default AddPosition;





